/*---------------------------------------------------

    Version: 1.0

-----------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Theme default CSS
        - Google Font 
        - Breadcrumb Css
    02. Header Css
        - Header Bottom Css
        - Main Menu Css
        - Shopping Cart Css
        - Mobile menu css
    03. Hero Css
    04. Search Css
    05. Product Area Css
    06. Testimonial
    07. Product Details Css
    08. Banner CSs
    09. Bloag Css
    10. Footer Css
    11. Widget Css
    12. Compare Page Css
    13. Login And Register Css
    14. My Account Page Css
    15. Error 404 Page Css
    16. Cart Page Css
    17. Checkout Page Css
    18. About Css
    19. Team Area Css
    20. Project Conunt Css  
    21. Contact css
 
--------------------------------------------------------*/

@import '_variabls';
@import '_mixin';
@import '_common';
@import '_header';
@import '_hero';
@import '_search.scss';
@import '_product';
@import '_testimonial';
@import '_product-details';
@import '_banner';
@import '_blog';
@import '_footer';
@import '_widget';
@import '_compare';
@import '_login-register';
@import '_my-account';
@import '_error-404';
@import '_cart';
@import '_checkout';
@import '_about-us';
@import '_team';
@import '_project-count';
@import '_contact-us';